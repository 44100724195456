@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* --background: 0 0% 100%; */
    --background: 49, 100%, 98%;
    --foreground: 222.2 84% 4.9%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    /* --popover: 0 0% 100%; */
    --popover: 49, 100%, 98%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    /* --card: 49, 100%, 98%; */
    --card-foreground: 222.2 84% 4.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 217.2 32.6% 17.5%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.link {
  @apply underline underline-offset-2 hover:cursor-pointer hover:text-muted-foreground/90;
}

/* This helps the formatting of the mapbox address input */
mapbox-address-autofill {
  display: flex;
}

mapbox-address-autofill > div {
  width: 100%;
}

.cl-userProfile-root > .cl-card {
  margin: -1px -29px;
}

.static-content {
  @apply text-foreground;
}

.static-content h1 {
  @apply my-6 text-6xl font-bold;
}

.static-content h2 {
  @apply my-6 text-4xl font-bold;
}

.static-content h3 {
  @apply my-6 text-2xl font-bold;
}

.static-content p {
  @apply my-6;
}

.static-content a {
  @apply underline hover:cursor-pointer hover:text-foreground/80;
}

/* .cl-card {
  @apply border-border bg-background text-foreground;
}
.cl-headerTitle,
.cl-headerSubtitle,
.cl-formFieldLabel,
.cl-footerActionText,
.cl-identityPreviewText,
.cl-organizationPreviewSecondaryIdentifier,
.cl-organizationSwitcherPopoverActionButtonText {
  @apply border-border text-foreground;
}
.cl-organizationSwitcherPopoverActionButtonText .cl-formFieldInput {
  @apply flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm text-foreground ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50;
} */
